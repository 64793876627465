/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lodash@4.17.4/lodash.min.js
 * - /npm/jquery.inputmask@3.3.4/dist/jquery.inputmask.bundle.js
 * - /npm/infinite-scroll@3.0.2/dist/infinite-scroll.pkgd.min.js
 * - /npm/kbw-datepick@5.1.1/dist/js/jquery.plugin.min.js
 * - /npm/kbw-datepick@5.1.1/dist/js/jquery.datepick.min.js
 * - /npm/kbw-datepick@5.1.1/dist/js/jquery.datepick-ru.min.js
 * - /npm/highcharts@6.2.0/js/highcharts.min.js
 * - /npm/highcharts@6.2.0/js/modules/no-data-to-display.min.js
 * - /npm/resize-sensor@0.0.6/ResizeSensor.min.js
 * - /npm/sticky-sidebar@3.3.1/dist/jquery.sticky-sidebar.min.js
 * - /npm/hc-sticky@2.1.0/dist/hc-sticky.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
